import { Instagram } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import RegistrationFrom from "../components/Formulator/registration-form";
import {
	EventStatus,
	useEventContext,
} from "../hooks/contexts/UseEventContext";
import { PageNotFound } from "./page-not-found";
import Countdown from "react-countdown";

const days_of_the_week: any = {
	الأحد: 1,
	الأثنين: 2,
	الثلاثاء: 3,
	الاربعاء: 4,
	الخميس: 5,
	الجمعة: 6,
	السبت: 7,
};
export function Registration() {
	const { event_name } = useParams();

	const { EventContext, event, setEvent } = useEventContext(
		event_name!.includes("-مع-")
			? {
					name: event_name!.split("-مع-")[1].replaceAll("-", " "),
					day_of_week: Number(
						days_of_the_week[event_name!.split("-مع-")[0]]
					),
			  }
			: {
					name: event_name!.split("-")[1],
					day_of_week: Number(
						days_of_the_week[event_name!.split("-")[0]]
					),
			  }
	);

	// useEffect(() => {
	// 	// console.log(event_name);
	// 	// console.log(
	// 	// 	"huuu",
	// 	// 	event_name!.split("-مع-")[1].replace("-", " "),
	// 	// 	Number(days_of_the_week[event_name!.split("-مع-")[0]]),
	// 	// 	event
	// 	// );
	// }, [event]);

	return (
		<EventContext.Context.Provider
			value={{
				event: event,
				setEvent: setEvent,
			}}
		>
			{event ? (
				event.id ? (
					event?.status == EventStatus.REGISTRATION_STARTED ? (
						<RegistrationFrom />
					) : event?.status == EventStatus.LAUNCHED ? (
						<Box
							sx={{
								height: "85vh",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#091a86",
								}}
							>
								التسجيل بفتح بعد
								<br></br>
								<br></br>
								<Countdown
									date={event.event_registration_date}
								/>
							</Typography>
							{window.document.location.origin.toLocaleLowerCase() ===
							"https://pop.watarelsharq.com" ? (
								<Box
									sx={{
										my: "30px",
										display: "flex",
										width: "100%",
										p: "100px",
										background: `url(${event?.svg_path}) no-repeat center`,
										alignSelf:"center",
										boxSizing:"border-box",

									}}
								></Box>
							) : (
								<Box
									sx={{
										// fill: "",
										m: "auto",
										my: "30px",
										display: "flex",
										minHeight: "150px",
										width: "100%",
										maxWidth: "500px",
										bgcolor: "#091a86",
										WebkitMask: `url(${event?.svg_path}) no-repeat center`,
										mask: `url(${event?.svg_path}) no-repeat center`,
									}}
								></Box>
							)}

							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#091a86",
								}}
							>
								{"يوم " + event?.getDay()}
							</Typography>
							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#f68a26",
								}}
							>
								{event?.getDate()}
							</Typography>
							<Typography sx={{ m: 2 }}>
								خلي عينك على صفحتنا على الانستجرام لتعرف اخبار
								هاي الحفلة والحفلة الجاي
							</Typography>

							<Button
								sx={{ maxWidth: "350px", mx: "auto" }}
								size="large"
								startIcon={<Instagram />}
								href={
									window.document.location.origin.toLocaleLowerCase() ===
									"https://pop.watarelsharq.com"
										? "https://instagram.com/watarpop"
										: "https://instagram.com/watarelsharq"
								}
							>
								WatarElSharq | وتر الشرق
							</Button>
						</Box>
					) : (
						<Box
							sx={{
								height: "85vh",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#091a86",
								}}
							>
								خلصت مقاعد حفلة
							</Typography>
							{window.document.location.origin.toLocaleLowerCase() ===
							"https://pop.watarelsharq.com" ? (
								<Box
									sx={{
										m: "auto",
										my: "30px",
										display: "flex",
										minHeight: "350px",
										width: "100%",
										maxWidth: "500px",
										background: `url(${event?.svg_path}) no-repeat center`,
									}}
								></Box>
							) : (
								<Box
									sx={{
										// fill: "",
										m: "auto",
										my: "30px",
										display: "flex",
										minHeight: "150px",
										width: "100%",
										maxWidth: "500px",
										bgcolor: "#091a86",
										WebkitMask: `url(${event?.svg_path}) no-repeat center`,
										mask: `url(${event?.svg_path}) no-repeat center`,
									}}
								></Box>
							)}

							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#091a86",
								}}
							>
								{"يوم " + event?.getDay()}
							</Typography>
							<Typography
								variant="h4"
								sx={{
									whiteSpace: "pre-wrap",
									fontWeight: "bold",
									color: "#f68a26",
								}}
							>
								{event?.getDate()}
							</Typography>
							<Typography sx={{ m: 2 }}>
								خلي عينك على صفحتنا على الانستجرام لتعرف اخبار
								هاي الحفلة والحفلة الجاي
							</Typography>

							<Button
								sx={{ maxWidth: "350px", mx: "auto" }}
								size="large"
								startIcon={<Instagram />}
								href={
									window.document.location.origin.toLocaleLowerCase() ===
									"https://pop.watarelsharq.com"
										? "https://instagram.com/watarpop"
										: "https://instagram.com/watarelsharq"
								}
							>
								WatarElSharq | وتر الشرق
							</Button>
						</Box>
					)
				) : (
					<PageNotFound />
				)
			) : (
				<></>
			)}
		</EventContext.Context.Provider>
	);
}
